import React, {Component} from 'react'
import Rodal from 'rodal';
import Explorer from './components/explorer'
import propTypes from 'prop-types';
import AssetActions from './actions/assetActions';
import {connect} from 'react-redux';

import 'rodal/lib/rodal.css';

// TODO: The following two style files need to be compiled from source when
//       we refactor into @dized/ui. Make sure to get the source files from
//       rules-ct when work on the tech debt task (probably DIZ-121)!
import './styles/styles.css';
import './styles/main.css';

const customModalStyles = {
  width: '80%',
  height: '80%',
  minWidth: '740px',
  minHeight: '600px',
  background: '#e9eff0'
};

function mapStateToProps(store) {
  return {assets: store.assets.assetui};
}

function mapDispatchToProps(dispatch) {
  return {actions: AssetActions.bindActions(dispatch)};
}

class AssetUI extends Component {

  static propTypes = {

    attr: propTypes.shape({
      mimeType: propTypes.string.isRequired,
      locale: propTypes.string.isRequired,
      aspectRatio: propTypes.string,
      selectionLimit: propTypes.number,
      size: propTypes.string,
      min: propTypes.bool,
      max: propTypes.bool,
      crop: propTypes.string,
      ignoreAR: propTypes.bool
    }),

    currentAsset: propTypes.string,
    organizationId: propTypes.string.isRequired,
    callBack: propTypes.func.isRequired,
    show: propTypes.bool.isRequired,
    close: propTypes.func.isRequired,

    actions: propTypes.shape({
      RESET_CONTENT: propTypes.func.isRequired,
    }).isRequired,
  };

  onClose = () => {
    const {close, actions: {RESET_CONTENT}} = this.props;

    return Promise.resolve()
      .then(() => close())
      .finally(() => RESET_CONTENT());
  };

  render() {
    const {show, ...rest} = this.props;
    return (
      <Rodal visible={show} onClose={this.onClose} customStyles={customModalStyles}>
        {show && <Explorer onClose={this.onClose} {...rest} />}
      </Rodal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetUI);


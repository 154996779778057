import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Button} from '@dized/ui';

import GameActions from '../../actions/GameActions';
import ProjectActions from '../../actions/ProjectActions';
import TranslationActions from '../../actions/TranslationActions';
import {
  ProjectTypeNames,
  editGameTranslations,
  editProject
} from '../../common/utils/projectUtils';
import {createProject} from '../../common/rulesHelpers/entityUtils';
import GameDetails from '../../components/GameDetails';
import {LocaleTable} from '../../components/LocaleTable';
import ProjectDetails from '../../components/ProjectDetails';
import {GridButton, GridDataTable} from '../../components/GridDataTable';
import {Row, Title} from '../../components/StyledComponents';

const mapStateToProps = store => ({
  organization: store.db.organization,
  projects: store.db.projects,
});

const mapDispatchToProps = dispatch => ({
  ...GameActions.bindActions(dispatch),
  ...ProjectActions.bindActions(dispatch),
  ...TranslationActions.bindActions(dispatch),
});

class GameInfo extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    organization: PropTypes.object.isRequired,
    projects: PropTypes.shape({
      projects: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
      })),
    }).isRequired,
    DELETE_GAME_LOCALE: PropTypes.func.isRequired,
    GET_GAME: PropTypes.func.isRequired,
    GET_GAME_LOCALES: PropTypes.func.isRequired,
    GET_LATEST_REVISION: PropTypes.func.isRequired,
    GET_PROJECTS: PropTypes.func.isRequired,
    PATCH_GAME_LOCALE: PropTypes.func.isRequired,
    POST_GAME_LOCALE: PropTypes.func.isRequired,
    POST_PROJECT: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    locales: [],
    showProjectDetails: undefined,
  };

  componentDidMount() {
    const {match, GET_GAME, GET_LATEST_REVISION, GET_PROJECTS} = this.props;
    const gameId = match.params.id;

    GET_GAME(gameId)
      .then(() => GET_LATEST_REVISION(gameId))
      .then(() => GET_PROJECTS(gameId))
      .then(this.getLocales);

    document.title = 'Dized Portal: Game Information';
  }

  /**
   * Extends React Component lifecycle API with a promisified version of setState()
   *
   * @param {object} newState - new component state passed to this.setState()
   * @returns {Promise}       resolves when component state has been updated
   */
  setStatePromise(newState) {
    return new Promise(resolve => this.setState(newState, resolve));
  }

  getLocales = () => {
    const {match, GET_GAME_LOCALES} = this.props;
    const {id} = match.params;

    return Promise.resolve()
      .then(() => GET_GAME_LOCALES(id))
      .then(response => this.setStatePromise({locales: response.value.data}));
  };

  onAddLocale = locale => {
    const {match, POST_GAME_LOCALE} = this.props;
    const {id} = match.params;

    return Promise.resolve()
      .then(() => POST_GAME_LOCALE(id, locale))
      .then(this.getLocales);
  };

  onDeleteLocale = locale => {
    const {match, DELETE_GAME_LOCALE} = this.props;
    const {id} = match.params;

    return Promise.resolve()
      .then(() => DELETE_GAME_LOCALE(id, locale))
      .then(this.getLocales);
  };

  onUpdateLocale = (locale, data) => {
    const {match, PATCH_GAME_LOCALE} = this.props;
    const {id} = match.params;

    return Promise.resolve()
      .then(() => PATCH_GAME_LOCALE(id, locale, data))
      .then(this.getLocales);
  };

  onEditTranslations = locale => {
    const {match} = this.props;
    const {id} = match.params;

    editGameTranslations(id, locale);
  };

  onGameEditClick = () => {
    const {history, match, organization} = this.props;
    const gameId = match.params.id;
    history.push(`/${organization.slug}/game/${gameId}/edit`);
  };

  onEditProjectClick(project) {
    const {match, organization} = this.props;
    const gameId = match.params.id;

    return editProject(organization, gameId, project.uuid, project.type);
  }

  onProjectDetailsClick(project) {
    const {history, match, organization} = this.props;
    const gameId = match.params.id;
    history.push(`/${organization.slug}/project/${gameId}/${project.uuid}`);
  }

  setProjectDetails(project, callback) {
    this.setState({showProjectDetails: project}, callback);
  }

  onAddProjectClick = () => {
    this.setProjectDetails({type: ''});
  };

  onDetailsClose = () => {
    this.setProjectDetails();
  };

  onDetailsConfirm = params => {
    const {match, organization, POST_PROJECT} = this.props;
    const gameId = match.params.id;

    return Promise.resolve()
      .then(() => new Promise(resolve => this.setProjectDetails(undefined, resolve)))
      .then(() => POST_PROJECT(
        createProject({
          ...params,
          uuid: gameId,
          organizationId: organization.uuid,
        })
      ));
  };

  render() {
    const {match, projects: {projects}} = this.props;
    const {locales, showProjectDetails} = this.state;
    const gameId = match.params.id;

    return (
      <>
        <GameDetails
          gameId={gameId}
        >
          <Button onClick={this.onGameEditClick}>Edit Game Details</Button>
        </GameDetails>
        <LocaleTable
          locales={locales}
          localeActions={locale =>
            <GridButton onClick={() => this.onEditTranslations(locale)}>Edit</GridButton>
          }
          onAddLocale={this.onAddLocale}
          onDeleteLocale={this.onDeleteLocale}
          onUpdateLocale={this.onUpdateLocale}
        />
        <Row>
          <Title>
            <h2>Projects</h2>
          </Title>
          <Button onClick={this.onAddProjectClick}>Add</Button>
        </Row>
        <GridDataTable
          columns={[
            {
              title: 'Id',
              renderCell: d => d.uuid,
              sortCell: (a, b) => a.uuid.localeCompare(b.uuid),
            },
            {
              title: 'Name',
              template: '1fr',
              renderCell: d => d.title.text,
              sortCell: (a, b) => a.title.text.localeCompare(b.title.text),
            },
            {
              title: 'Type',
              renderCell: d => ProjectTypeNames[d.type],
              sortCell: (a, b) =>
                ProjectTypeNames[a.type].localeCompare(ProjectTypeNames[b.type]),
            },
            {
              title: 'Status',
              renderCell: d => d.status,
              sortCell: (a, b) => a.status.localeCompare(b.status),
            },
            {
              title: '',
              renderCell: d => (
                <>
                  <GridButton onClick={() => this.onProjectDetailsClick(d)}>Details</GridButton>
                  <GridButton onClick={() => this.onEditProjectClick(d)}>Edit</GridButton>
                </>
              ),
            },
          ]}
          data={[...projects].sort((a, b) => a.created - b.created)}
        />
        {showProjectDetails && (
          <ProjectDetails
            {...showProjectDetails}
            types={projects.map(p => p.type)}
            onClose={this.onDetailsClose}
            onConfirm={this.onDetailsConfirm}
          />
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameInfo);

import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {DownloadButton, DropDown, DropDownMenu, ModalConfirmation} from '@dized/ui';

import {GridButton, GridDataTable} from '../GridDataTable';
import {Row, Title} from '../StyledComponents';

import {getLocaleName, sortedLocaleList} from './LocaleList';

const StyledDownloadButton = styled(DownloadButton)`
  margin: 0 2em;
`;

export const LocaleTable = ({
  download: {generateFile, initialValue, text} = {},
  locales,
  localeActions,
  onAddLocale,
  onDeleteLocale,
  onUpdateLocale,
}) => {
  const [deleteLocale, updateDeleteLocale] = useState(undefined);

  const clearDeleteLocale = useCallback(
    () => updateDeleteLocale(),
    []
  );

  const localeMap = locales.reduce(
    (a, c) => {
      const {locale, master = false} = c;

      a[locale] = {
        ...c,
        master,
        name: getLocaleName(locale),
      };

      return a;
    },
    {}
  );
  const languages = sortedLocaleList.filter(({value}) => !Object.hasOwnProperty.call(localeMap, value));

  return (
    <>
      <Row>
        <Title>
          <h2>Languages</h2>
        </Title>
        {generateFile && initialValue && (
          <StyledDownloadButton
            generateFile={generateFile}
            initialValue={initialValue}
          >
            {text ?? 'Download'}
          </StyledDownloadButton>
        )}
        <DropDownMenu
          actions={languages}
          direction="DownLeft"
          icon="add"
          onClick={locale => onAddLocale(locale)}
        />
      </Row>
      <GridDataTable
        columns={[
          {
            title: 'Locale',
            template: '1fr',
            renderCell: d => d.name,
            sortCell: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: 'Status',
            // eslint-disable-next-line react/display-name, react/prop-types
            renderCell: ({locale, master, state}) => master || (
              <DropDown
                onChange={event => onUpdateLocale(locale, {state: event.target.value})}
                options={[
                  {label: 'Draft',       value: 'draft'},
                  {label: 'Unpublished', value: 'unpublished'},
                  {label: 'Published',   value: 'published'},
                ]}
                value={state ?? 'draft'}
              />
            )
          },
          {
            title: '',
            // eslint-disable-next-line react/display-name
            renderCell: d => d.master || (
              <>
                <GridButton onClick={() => updateDeleteLocale(d)}>Delete</GridButton>
                {localeActions(d.locale)}
              </>
            ),
          },
        ]}
        data={Object.values(localeMap).sort((a, b) => a.name - b.name)}
      />
      {deleteLocale && (
        <ModalConfirmation
          header={`Delete language '${deleteLocale.name}'`}
          labelConfirm='Delete'
          onCancel={clearDeleteLocale}
          onClose={clearDeleteLocale}
          onConfirm={() => {
            clearDeleteLocale();
            onDeleteLocale(deleteLocale.locale);
          }}
          visible
        >
          Do you really want to remove this language from the project?
        </ModalConfirmation>
      )}
    </>
  );
}

LocaleTable.propTypes = {
  download: PropTypes.shape({
    generateFile: PropTypes.func.isRequired,
    initialValue: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  /** GET_XXX_LOCALES() response.value.data */
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      master: PropTypes.bool,
      state: PropTypes.string,
    })
  ).isRequired,
  localeActions: PropTypes.func.isRequired,
  onAddLocale: PropTypes.func.isRequired,
  onDeleteLocale: PropTypes.func.isRequired,
  onUpdateLocale: PropTypes.func.isRequired,
};

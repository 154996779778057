import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {del, get, patch, post} from '@dized/ui/dist/redux/RequestHelper';

const path = '/api/v1/translation';

export default initActions('TranslationActions', {
  DELETE_GAME_LOCALE: (gameId, locale) => del(`${path}/game/${gameId}/${locale}`),
  DELETE_PROJECT_LOCALE: (projectId, locale) => del(`${path}/project/${projectId}/${locale}`),
  GET_GAME_LOCALES: gameId => get(`${path}/game/${gameId}`),
  GET_PROJECT_LOCALES: projectId => get(`${path}/project/${projectId}`),
  GET_PROJECT_LOCALE_CSV: (projectId, locale) => get(`${path}/project/${projectId}/${locale}/csv`),
  PATCH_GAME_LOCALE: (gameId, locale, data) => patch(`${path}/game/${gameId}/${locale}`, data),
  PATCH_PROJECT_LOCALE: (projectId, locale, data) => patch(`${path}/project/${projectId}/${locale}`, data),
  POST_GAME_LOCALE: (gameId, locale) => post(`${path}/game/${gameId}`, {locale}),
  POST_PROJECT_LOCALE: (projectId, locale) => post(`${path}/project/${projectId}`, {locale}),
});
